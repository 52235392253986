import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

const reload = () => window.location.reload();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path='/:room/index.html' onEnter={reload} />
        <Route path='/:room' component={({match}) => {
          console.log(match); 
          const urlParts = [
            'https://virtualengage.arup.com',
            match.url,
            '/index.html'
          ];
          window.location.href = "".concat(...urlParts); 
          return null;
        }}/>
        <Route path='/' component={() => {
          window.location.href = 'https://www.arup.com';
          return null;
        }}/>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
